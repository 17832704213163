import * as React from "react";
// import Helmet from "react-helmet";

// markup
const IndexPage = () => {
  return (
    <>
      <div className="center">Hashku Minting</div>
    </>
  );
};

export default IndexPage;
